/**
 * support(contact-us页)
 */
export default {
  pageName: 'page_contact_us',
  newPosKeys: [
    'IsCanRefund'
  ],
  posKeys: [],
}
