import { apolloSuggestedSaleConfig } from '../constant.js'
import { getCardConfig, getEstimatedPriceAndLabel, getSheinClubPriceAndLabel, getExclusivePriceAndLabel, getNewFlashPriceAndLabel, getFlashZonePriceAndLabel, getSalePriceAndLabel } from './priceUtils.js'

export { isDeSuggestedSalePrice, isNomalSuggestedSalePrice, isSpecialSuggestedSalePrice, isFrPlSuggestedSalePrice } from './priceUtils.js'


/**
 * 获取最终展示的价格相关信息以及折扣信息
 * @param {{
 *   isPaid: Boolean, 是否付费会员
 *   goodsInfo: Object, 商品数据
 *   language: Object,  商卡多语言
 *   config: Object     商卡config配置项
 *   suggestedSalePriceConfigInfo: Object 合规价配置项，不经中间层预处理使用价格组件使用（如榜单插坑场景）
 * }} options 预处理完商品数据
 * @returns {String}
 */
export class ProductCardPriceAndDiscountInfo {
  // 默认价格展示优先级
  #defaultPricePriority = ['estimatedPrice', 'sheinClubPrice', 'exclusivePrice', 'newFlashPrice', 'flashZonePrice', 'salePrice']
  #priceDataMap = {
    estimatedPrice: getEstimatedPriceAndLabel,
    sheinClubPrice: getSheinClubPriceAndLabel,
    exclusivePrice: getExclusivePriceAndLabel,
    newFlashPrice: getNewFlashPriceAndLabel,
    flashZonePrice: getFlashZonePriceAndLabel,
    salePrice: getSalePriceAndLabel
  }

  /**
   * 
   * @param {{
   *   source: String, 来源,在config判断时使用 list：默认全关，只有为true的配置项才会取值   other: 默认全开，只有为false的配置项才会忽略
   *   isPaid: Boolean, 是否付费会员
   *   goodsInfo: Object, 商品数据
   *   language: Object,  商卡多语言
   *   config: Object     商卡config配置项，计算价格会用到
   *   suggestedSalePriceConfigInfo: Object 合规价配置项，不经中间层预处理使用价格组件使用（如榜单插坑场景）
   * }} options 
  */
  constructor(options) {
    
    this.source = options?.source ?? 'other'

    // 是否会员
    this.isPaid = options?.isPaid ?? false

    // 商品数据
    this.goodsInfo = options?.goodsInfo ?? {}

    // 多语言
    this.language = options?.language ?? {}

    // 是否多行商卡
    this.multiColumn = options?.multiColumn ?? false

    // 商卡config配置项
    this.config = options?.config ?? {}

    // 自定义传入的合规价配置项
    this.customSuggestedSalePriceConfigInfo = options?.suggestedSalePriceConfigInfo ?? {}

    /**
     * 最终展示的价格类型 priceDataMap对象的key
     * @enum {String} estimatedPrice sheinClubPrice exclusivePrice newFlashPrice flashZonePrice salePrice
     */
    this.finalPriceType = ''
    
    /**
     * 最终展示价格
     * @type {{
     *  amount: Number, 当前站点价格
     *  amountSimpleText: String, 当前站点价格文案
     *  amountWithSymbol: String, 当前站点价格带符号
     *  usdAmount: Number, 美元价格
     *  usdAmountWithSymbol: String, 美元价格带符号
     * }}
     */
    this.finalPrice = {}
    // 合规站点下，30天最低价
    this.finalSuggestedSalePrice = {}
    // 展示的价格是否倒挂，法国波兰合规场景用到  为1倒挂则不展示30天最低价
    this.finalPriceIsInversion = 0
    

    // 一些价格的状态集合
    this.priceStateCollection = {
      // 最终价格是否ab价， 1普通ab价  2付费会员ab价
      finalABPriceType: 0, // 是否AB价
      hasEstimatedPrice: false, // 是否有到手价并且满足门槛
      hasFlashPromotion: false, // 是否有闪购活动
      priceIsRemovePriceEstimatedPrice: false, // 主价格是否移除了到手价信息
      hideDiscountLabel: false, // 是否隐藏折扣标签
    }

    // 最终展示折扣信息
    /**
     * @type {{
     *  show: Boolean, 是否展示折扣
     *  unitDiscountString: String, 显示的折扣率 -**%
     *  retailDiscountPercentString: String, 德国合规价原价折扣率
     * }}
     */
    this.finalDiscount = {}

    // 最终合并的合规价以及原价等判断结果以及配置信息
    this.suggestedSalePriceData = {}

    // 包含：合规价、原价、30天最低价等
    this.priceRight = {}
    this.priceBottom = {}

    // 建议零售价 波兰专用
    this.priceBottomRrp = {}

    // 底部价格标签文字
    this.priceLabelText = ''

    // 价格颜色  origin橙色促销色   brown棕色会员色  darkGold深金色s3会员
    this.color = ''

    this.init()
  }

  // 多行商卡是否显示德国合规价
  get disableMultiColumnSuggestedSalePrice() {
    return this.multiColumn && !this.config?.showMultiColumnSuggestedSalePrice
  }

  /**
   * 获取合规价当前站点配置类型
   * 内部使用，外部请用suggestedSalePriceData
   */
  get suggestedSalePriceConfigInfo() {
    if (this.customSuggestedSalePriceConfigInfo?.type) {
      return this.customSuggestedSalePriceConfigInfo
    } else {
      return this.goodsInfo?.pretreatInfo?.suggestedSalePriceConfigInfo || {}
    }
  }

  // 法国合规价
  get isNormalSuggested() {
    const type = this.suggestedSalePriceConfigInfo?.type
    return type === apolloSuggestedSaleConfig.NORMAL
  } 

  // 波兰合规价
  get isSpecialSuggested() {
    const type = this.suggestedSalePriceConfigInfo?.type
    return type === apolloSuggestedSaleConfig.SPECIAL
  }
  
  // 德国合规价
  get isSpecialDeSuggested() {
    const type = this.suggestedSalePriceConfigInfo?.type
    return type === apolloSuggestedSaleConfig.SPECIALDE
  } 

  // 初始化
  init() {
    const hasFlashPromotion = this.goodsInfo?.promotionInfo?.some(item => +item?.typeId === 10)

    // 是否剔除到手价
    const priceExclusionLogic = this.goodsInfo?.estimatedPriceInfo?.satisfied === 1 && 
      getCardConfig(this.config, 'showNewStyleEstimated', false)

    this.initPriceAndLabelText({ priceExclusionLogic })

    this.initSuggestedSalePrice()

    this.initDiscount()

    this.priceStateCollection.priceIsRemovePriceEstimatedPrice = priceExclusionLogic
    this.priceStateCollection.hasFlashPromotion = hasFlashPromotion

    // 价格变动后需要重新计算的逻辑 start
    this.handleUnderLinePricePosition()

    if (this.suggestedSalePriceConfigInfo?.type) {
      const { show30DaysLowestPrice, priceInfoAndretailPriceEquivalent } = this.suggestedSalePriceData

      // 法国波兰合规
      const frPl = this.isNormalSuggested || this.isSpecialSuggested

      // 德国合规非多行商卡
      const deNomultiColumn = !this.disableMultiColumnSuggestedSalePrice && this.isSpecialDeSuggested

      // 德国不满足条件或多行商卡显示黑色颜色以及合规价
      const deNoColor = this.isSpecialDeSuggested && 
        (
          (!show30DaysLowestPrice && !priceInfoAndretailPriceEquivalent) || 
          this.disableMultiColumnSuggestedSalePrice
        )

      const frPlNoColor = frPl && !show30DaysLowestPrice

      // 如果价格为售价， 只要不是倒挂，都展示促销色
      if ((frPl || deNomultiColumn) && this.finalPriceType === 'salePrice' && show30DaysLowestPrice) {
        this.color = 'origin'
      } else if (deNoColor || frPlNoColor) {
        // 合规站点黑色价格计算
        this.color = ''
      }
    }
    // 需要重新计算的逻辑 end
  }

  /**
   * 获取最终展示的价格对象
  */
  priceDisplayTheResults(options) {

    const priceExclusionLogic = options?.priceExclusionLogic || false

    // 到手价不走主价格，使用额外标签显示
    let defaultPricePriority = this.#defaultPricePriority
    
    if (priceExclusionLogic) {
      defaultPricePriority = defaultPricePriority.filter(v => {
        if (getCardConfig(this.config, 'showNewStyleEstimated', false)) {
          return v !== 'estimatedPrice'
        } else {
          return true
        }
      })
    }
    
    let priceResults = {}
    for (let i = 0; i < defaultPricePriority.length; i++) {
      const key = defaultPricePriority[i]
      const priceData = this.#priceDataMap[key]?.(this.goodsInfo, this.language, {
        config: this.config,
        isPaid: this.isPaid,
        source: this.source
      }) || {}

      // 是否为付费会员价格  付费会员价 以及 付费会员到手价 estimatedPriceType === 2
      const isSheinClubPriceRes = key === 'sheinClubPrice' || priceData?.estimatedPriceType === 2
      const flag = isSheinClubPriceRes ? this.isPaid : true

      if (priceData.priceInfo?.amountWithSymbol && flag) {
        priceResults = {
          ...priceData,
          priceType: key,
        }

        break
      }
    }

    // 合规价场景下，隐藏价格说明文字
    const SuggestedSalePriceType = this.suggestedSalePriceConfigInfo?.type
    if (SuggestedSalePriceType && priceResults?.priceInfo) {
      priceResults.labelText = ''
    }
    
    return priceResults
  }

  /** 计算最终价格以及下方文字
   * 
   */
  initPriceAndLabelText(options) {
    const priceResults = this.priceDisplayTheResults(options)

    this.finalPrice = priceResults?.priceInfo || {}
    this.finalSuggestedSalePrice = priceResults?.suggestedSalePrice || {}
    this.finalPriceType = priceResults?.priceType || ''
    this.priceLabelText = priceResults?.labelText || ''
    this.color = priceResults?.color || ''

    // 到手价
    const { estimatedPrice, satisfied } = this.goodsInfo.estimatedPriceInfo ?? {}
    const hasEstimatedPrice = satisfied === 1 && estimatedPrice?.amountWithSymbol

    const hasAbPricePromition = this.goodsInfo?.promotionInfo?.some(item => +item?.typeId === 32)
    const hassheinClubPricePromition = this.goodsInfo?.sheinClubPromotionInfo?.price?.amount

    let finalABPriceType = 0
    if (hasAbPricePromition && hassheinClubPricePromition) {
      finalABPriceType = 2
    } else if (this.finalPriceType === 'salePrice' && hasAbPricePromition) {
      finalABPriceType = 1
    }

    // ab价 命中中东到手价实验时 商品没有到手价 有ab价 底部文案改变
    if (finalABPriceType === 1 && getCardConfig(this.config, 'showNewStyleEstimated', false) && !hasEstimatedPrice) {
      const withCouponText = this.language?.SHEIN_KEY_PC_31839 || 'with Coupon'
      this.priceLabelText = withCouponText
    }
    
    this.priceStateCollection.hasEstimatedPrice = hasEstimatedPrice
    this.priceStateCollection.finalABPriceType = finalABPriceType

    if (this.isNormalSuggested || this.isSpecialSuggested) {
      this.finalPriceIsInversion = priceResults?.isInversion || 0
    }

    if (this.config.hidePriceBottomLabel) {
      this.priceLabelText = ''
    }
  }

  // 显示折扣计算
  initDiscount() {
    const discountInfo = Object.assign({}, this.goodsInfo.pretreatInfo?.discountInfo)

    if (!discountInfo || this.finalPriceIsInversion) return false

    const sheinClubPromotionInfo = this.goodsInfo.sheinClubPromotionInfo || {}
    const exclusivePromotionInfo = this.goodsInfo.exclusivePromotionInfo || {}

    const { estimatedDiscount, estimatedDiscountPrice, estimatedPriceType } = this.goodsInfo.estimatedPriceInfo || {}
    
    const setDiscountInfo = (unitDiscountString, discountPrice) => {
      discountInfo.show = true
      discountInfo.unitDiscountString = unitDiscountString
      discountInfo.discountPrice = discountPrice
    }

    const handleSalePriceDiscount = () => {
      const discountPrice = this.goodsInfo.discountPrice || {}
      const { retailDiscountPercent, retailDiscountPrice, retailDiscountPercentString } = discountInfo

      if (this.isSpecialDeSuggested && retailDiscountPercent > 0 && this.suggestedSalePriceData.showRetailPrice) {
        setDiscountInfo(retailDiscountPercentString, retailDiscountPrice?.amountWithSymbol)
      } else if (discountPrice.amount && discountInfo.show) {
        setDiscountInfo(discountInfo.unitDiscountString, discountPrice.amountWithSymbol)
      } else {
        discountInfo.show = false
      }
    }

    // 闪购在中间层预处理已处理完毕，此处只处理 到手价 付费会员折扣率
    // 到手价，无接入合规 取到手价折扣率
    if (this.finalPriceType === 'estimatedPrice' && estimatedDiscount) {
      setDiscountInfo(estimatedDiscount, estimatedDiscountPrice?.amountWithSymbol)
      
      // s3到手价 不展示折扣标签
      if (estimatedPriceType === 4) {
        this.priceStateCollection.hideDiscountLabel = true
      }
    } else if (this.finalPriceType === 'sheinClubPrice') {
      // 付费会员 取付费会员折扣率
      if (this.isSpecialDeSuggested && this.suggestedSalePriceData.showRetailPrice && sheinClubPromotionInfo.retailDiscountPercentString) {
        setDiscountInfo(sheinClubPromotionInfo.retailDiscountPercentString, sheinClubPromotionInfo.retail_discount_price.amountWithSymbol)
      } else if (!this.isSpecialDeSuggested && sheinClubPromotionInfo.discountPercentString) {
        setDiscountInfo(sheinClubPromotionInfo.discountPercentString, sheinClubPromotionInfo.discount_amount.amountWithSymbol)
      } else {
        discountInfo.show = false
      }
    } else if (this.finalPriceType === 'exclusivePrice') {
      // s3会员
      if (this.isSpecialDeSuggested && this.suggestedSalePriceData.showRetailPrice && exclusivePromotionInfo.retailDiscountPercentString) {
        setDiscountInfo(exclusivePromotionInfo.retailDiscountPercentString, exclusivePromotionInfo.retail_discount_price.amountWithSymbol)
      } else if (exclusivePromotionInfo.discountPercentString) {
        setDiscountInfo(exclusivePromotionInfo.discountPercentString, exclusivePromotionInfo.discount_amount.amountWithSymbol)
        // s3 非德国合规场景默认不展示折扣
        this.priceStateCollection.hideDiscountLabel = true
      } else {
        discountInfo.show = false
      }
    } else {
      // 普通折扣
      // 售价
      handleSalePriceDiscount()
    }

    // s3到手价标签 不展示折扣标签
    if (this.goodsInfo?.estimatedPriceInfo?.estimatedPriceType === 4 && this.priceStateCollection.priceIsRemovePriceEstimatedPrice) {
      this.priceStateCollection.hideDiscountLabel = true
    }

    this.finalDiscount = discountInfo
  }

  // 处理合规价 原价划线价位置（在售价的左边还是右边）
  handleUnderLinePricePosition() {

    const { retailPrice } = this.goodsInfo

    const priceRight = {
      show: false,        // 是否展示
      flipText: false,    //是否文案价格反转
      priceData: {},      // 显示的价格数据
      labelText: '',      // 价格右边的文案
      popoverLang: '',     // 价格右边的气泡文字
      priceDeleteStyle: true // 价格是否使用删除线样式
    }
    const priceBottom = {
      show: false,
      flipText: false,
      priceData: {},
      labelText: '',
      priceDeleteStyle: true
    }

    const priceBottomRrp = {
      show: false,
      flipText: false,
      priceData: {},
      labelText: '',
      popoverLang: ''
    }

    if (this.suggestedSalePriceData?.type) {
      //  合规价逻辑处理
      const { show30DaysLowestPrice, showRetailPrice, showRetailPricePopover, lowestPriceLabelLang, pricePopoverLang } = this.suggestedSalePriceData

      if (this.isSpecialSuggested) {
        // 波兰 isSpecial 显示划线价 零售价

        if (!this.finalPriceIsInversion) {
          // 划线价处理 放价格下边
          priceBottom.show = show30DaysLowestPrice
          priceBottom.priceData = this.finalSuggestedSalePrice
          priceBottom.labelText = lowestPriceLabelLang
          priceBottom.flipText = false
        }

        // 建议零售价
        // RRP价 > 主价格  && RRP价 > 30天最低价
        const { srpPriceInfo } = this.goodsInfo
        const { srpPrice, description, fullDescription } = srpPriceInfo || {}
        const srpAmount = Number(srpPrice?.amount || 0)
        const suggestedSaleAmount = Number(this.finalSuggestedSalePrice?.amount || 0)
        const priceAmount = Number(this.finalPrice?.amount || 0)

        if (getCardConfig(this.config, 'showRrpPrice', false) && srpAmount > suggestedSaleAmount && srpAmount > priceAmount) {
          priceBottomRrp.show = true
          priceBottomRrp.flipText = true
          priceBottomRrp.priceData = srpPrice
          priceBottomRrp.labelText = description
          priceBottomRrp.popoverLang = fullDescription
          priceBottomRrp.showRetailPricePopover = true
        }

      } else if (this.isNormalSuggested &&  !this.finalPriceIsInversion) {
        // 法国 价格右边显示划线价 不显示labelText
        priceRight.show = show30DaysLowestPrice
        priceRight.priceData = this.finalSuggestedSalePrice
        priceRight.labelText = ''
        priceRight.flipText = false
        
      } else if (this.isSpecialDeSuggested) {
        
        // 德国 价格右边显示原价  价格下边显示划线价

        //deEquallyDisplayTheSuggestedSalePrice 为首页商卡需求： 当30天最低价等于原价时，不展示icon，展示30天最低价
        
        priceRight.show = !!showRetailPrice
        priceRight.priceData = retailPrice
        priceRight.labelText = ''
        priceRight.flipText = false
        priceRight.showRetailPricePopover = showRetailPricePopover && !this.config?.deEquallyDisplayTheSuggestedSalePrice
        priceRight.popoverLang = showRetailPricePopover ? pricePopoverLang : ''
        
        if (this.config?.deEquallyDisplayTheSuggestedSalePrice && showRetailPricePopover) {
          priceBottom.show = true
        } else {
          priceBottom.show = show30DaysLowestPrice
        }

        priceBottom.priceData = this?.finalSuggestedSalePrice
        priceBottom.labelText = lowestPriceLabelLang
        priceBottom.flipText = true
      }

    } else if (this.config.showRetailPrice && !this.suggestedSalePriceData.type) {
      // 展示原价逻辑处理
      const show = Number(retailPrice?.amount || 0) > Number(this.finalPrice?.amount || 0)

      priceRight.show = show
      priceRight.priceData = retailPrice
      priceRight.labelText = ''
      priceRight.flipText = false
      priceRight.showRetailPricePopover = false
      priceRight.popoverLang = ''
    }
    
    this.priceRight = priceRight
    this.priceBottom = priceBottom
    this.priceBottomRrp = priceBottomRrp
  }

  /** 获取合规价相关配置
   * @param {Boolean} needSusaPrice 是否需要进行合规价处理
   * @param {Boolean} showRetailPrice 是否展示原价(划线价)
   * @param {Boolean} showRetailPricePopover 是否展示原价点击气泡(德国合规)
   * @param {Boolean} show30DaysLowestPrice 是否展示30天最低价
   * @param {Boolean} priceInfoAndretailPriceEquivalent 30天最低价与原价是否等价
   */
  initSuggestedSalePrice() {
    if (!this.suggestedSalePriceConfigInfo?.type) return false
    
    let suggestedSalePriceInfo = {
      config: this.suggestedSalePriceConfigInfo?.config ?? {},
      type: this.suggestedSalePriceConfigInfo?.type ?? '',
      lowestPriceLabelLang: this.suggestedSalePriceConfigInfo?.lowestPriceLabelLang || '',
      pricePopoverLang: this.suggestedSalePriceConfigInfo?.pricePopoverLang || '',
    }

    if (this.isNormalSuggested || this.isSpecialSuggested) {
      this.suggestedSalePriceData = Object.assign(suggestedSalePriceInfo, this.handleSuggestedSalePriceNormalAndSpecial())
    } else if (this.isSpecialDeSuggested) {
      this.suggestedSalePriceData = Object.assign(suggestedSalePriceInfo,  this.handleSuggestedSalePriceSpecialDe())
    }

    if (this.suggestedSalePriceData?.config?.hideDiscount) {
      this.priceStateCollection.hideDiscountLabel = true
    }
  }

  /**
   * 法国波兰合规价处理
   * @returns  {
  *  needSusaPrice: boolean, // 是否走合规价逻辑
  *  show30DaysLowestPrice: boolean, // 是否展示30天最低价
  * }
 */
  handleSuggestedSalePriceNormalAndSpecial() {
    const hideSuggestedSalePrice = this.suggestedSalePriceConfigInfo.config?.hideSuggestedSalePrice
    const suggestedSalePriceAmount = Number(this.finalSuggestedSalePrice?.amount || 0)
    
    return {
      needSusaPrice: true,
      show30DaysLowestPrice: !hideSuggestedSalePrice && !!(suggestedSalePriceAmount > 0 && !this.finalPriceIsInversion)
    }
  }

  /**
   * 德国合规价处理
   * @returns  {
   *  needSusaPrice: boolean, // 是否走合规价逻辑
   *  showRetailPrice: boolean, // 是否展示原价(划线价)
   *  showRetailPricePopover: boolean, // 是否展示原价点击气泡
   *  show30DaysLowestPrice: boolean, // 是否展示30天最低价
   *  priceInfoAndretailPriceEquivalent // 30天最低价与原价是否等价
   * }
  */
  handleSuggestedSalePriceSpecialDe() {
    const hideSuggestedSalePrice = this.suggestedSalePriceConfigInfo.config?.hideSuggestedSalePrice || this.disableMultiColumnSuggestedSalePrice
    const noRetailSuggestedJudge = this.config?.noRetailSuggestedJudge || false // 是否原价与合规价判断。判断时，原价等于合规价，展示气泡
    const { retailPrice } = this.goodsInfo

    // 30天最低价
    // 展示条件： 大于等于原价
    const suggestedSalePriceAmount = Number(this.finalSuggestedSalePrice?.amount || 0)

    const priceInfoAmount = Number(this.finalPrice?.amount || 0)
    const retailPriceAmount = Number(retailPrice?.amount || 0)

    // 是否展示30天最低价  30天最低价 > 0 & 30天最低价<售价 && 30天最低价<原价
    let show30DaysLowestPrice = suggestedSalePriceAmount > 0 && 
      !(suggestedSalePriceAmount < priceInfoAmount && priceInfoAmount === retailPriceAmount)

    if (show30DaysLowestPrice && !noRetailSuggestedJudge) {
      show30DaysLowestPrice = suggestedSalePriceAmount !== retailPriceAmount
    }
    
    // 是否展示原价(划线价) 30天最低价 > 0 & 售价＜原价 & 30天最低价<原价
    let showRetailPrice = suggestedSalePriceAmount > 0 && priceInfoAmount < retailPriceAmount && suggestedSalePriceAmount <= retailPriceAmount

    // 是否展示原价点击气泡  30天最低价=原价时，隐藏30天最低价 展示原价并且有气泡
    let showRetailPricePopover = showRetailPrice && !noRetailSuggestedJudge && suggestedSalePriceAmount === retailPriceAmount
    return {
      needSusaPrice: true, // 是否走合规价逻辑
      showRetailPrice: !hideSuggestedSalePrice && showRetailPrice,  // 是否展示原价(划线价)
      showRetailPricePopover: !hideSuggestedSalePrice && showRetailPricePopover, // 是否展示原价点击气泡
      show30DaysLowestPrice: !hideSuggestedSalePrice && show30DaysLowestPrice, // 是否展示30天最低价
      priceInfoAndretailPriceEquivalent: suggestedSalePriceAmount > 0 && retailPriceAmount === suggestedSalePriceAmount &&
        suggestedSalePriceAmount !== priceInfoAmount, // 30天最低价与原价是否等价
    }
  }
}

