export default [
  {
    pageName: 'page_me', // 个人中心页
    newPosKeys: [
      'pcmeRecommend',
      'hidePaymentOptions',
      'sheinclubme',
      'ClubRenewMePop'
    ],
    posKeys: ['sheinclubfreetrial']
  },
  {
    pageName: 'page_collection', // 收藏 （pageName: 从控制台打印：window?.SaPageInfo?.page_name ）
    newPosKeys: [
      // ! PV埋点需要 后续新增的埋点加在 controllers/user/wishlist_utils.js
      'WishlistsoldoutSimilar', 
      'WishlistInStock', 
      'WishlistInStock', 
      'discountLabel', 
      'greysellingPoint', 
      'goodsPicAb', 
      'sheinappwordsize', 
      'goodsSpace', 
      'componentswitch',
      'FollowLabel',
      'imageLabel',
      'discountLabel',
      'greysellingPoint',
      'sheinappwordsize',
      'goodsSpace',
      'Wishlistuplimit',
      'carddiscountLabel',
      'WishlistLabelwb'
    ],
    posKeys: [
      'WishlistRecommendationsForYou',
      'WishlistTag',
      'WishlistFilterdelete',
      'SellingPoint',
      'ItemAddToBoard',
      'WishItemSize',
      'AllListQuickShip',
    ]
  },
  {
    pageName: 'page_collection_boards', // 收藏分类页
    newPosKeys: [
      // ! PV埋点需要 后续新增的埋点加在 controllers/user/wishlist_utils.js
      'componentswitch', 'WishlistsoldoutSimilar', 'carddiscountLabel', 'WishlistLabelwb'
    ],
    posKeys: ['AllListQuickShip']
  },
  {
    pageName: 'page_group_share', // group分享页
    newPosKeys: ['sheinappwordsize', 'goodsSpace', 'pclistCropping'],
    posKeys: ['AllListQuickShip']
  },
  {
    pageName: 'page_brand_collection', // 店铺收藏
    newPosKeys: [],
    posKeys: []
  },
  {
    pageName: 'page_payment_options',  // 支付方式
    newPosKeys: ['bindPaymentOption'],
    posKeys: []
  },
  {
    pageName: 'page_recently_viewed',  //足迹页
    newPosKeys: [
      'imageLabel', 
      'discountLabel', 
      'greysellingPoint', 
      'sheinappwordsize', 
      'goodsSpace', 
      'pclistnewCard',
      'listKeyAttribute',
      'AllListStarReview',
      'listflashSale',
      'pcwordSize',
      'listgoodsCard',
      'FlashSaleCountDown',
      'EstimatedPrice',
      'listtagSorting',
      'listrankingTag',
      'listattributeLabels',
      'listquickshipLanguage',
      'carddiscountLabel'
    ],
    posKeys: [
      'AllListQuickShip'
    ]
  }
]
